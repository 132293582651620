@import "swiper/swiper-bundle.css";
@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "@typeform/embed/build/css/popup.css";

section {
  @apply scroll-mt-30;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (min-width: 380px) {
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.button-medium--dark{
  @apply text-center py-3 px-9 bg-blue-500 sm:hover:bg-blue-700 sm:focus:bg-blue-500 rounded-full text-16 leading-150
  text-white font-700 ring-blue-700 sm:focus:ring-4 transition-colors duration-150 ease-linear;
}

.button-medium--light{
  @apply text-center py-3 px-9 bg-white border border-gray-300 sm:hover:bg-black rounded-full text-16 leading-150
  text-black sm:hover:text-white font-700 ring-black sm:focus:ring-4 transition-colors duration-150 ease-linear;
}

.swiper-pagination-bullet{
  @apply w-3 h-3 bg-white opacity-20 transition-opacity duration-150 ease-linear
}

.swiper-pagination-bullet-active{
  @apply w-3 h-3 bg-white opacity-100 transition-opacity duration-150 ease-linear
}

.scroll-fade {
  @apply transition-[opacity,transform] duration-500 ease-out
}

.gradient-blue-100{
  background-color: hsla(244, 62%, 56%, 1);
  background-image: radial-gradient(at 68% 63%, hsla(204, 73%, 66%, 1) 0px, transparent 50%),
  radial-gradient(at 96% 4%, hsla(191, 90%, 78%, 1) 0px, transparent 50%),
  radial-gradient(at 30% 87%, hsla(213, 68%, 63%, 1) 0px, transparent 50%);
}

.gradient-blue-200{
  background-color: hsla(244, 62%, 56%, 1);
  background-image: radial-gradient(at 77% 91%, hsla(201, 86%, 55%, 1) 0px, transparent 50%),
  radial-gradient(at 68% 63%, hsla(204, 73%, 66%, 1) 0px, transparent 50%),
  radial-gradient(at 96% 4%, hsla(191, 90%, 78%, 1) 0px, transparent 50%),
  radial-gradient(at 30% 87%, hsla(213, 68%, 63%, 1) 0px, transparent 50%);
}

.gradient-green{
  background-color: hsla(166, 44%, 59%, 1);
  background-image: radial-gradient(at 82% 73%, hsla(175, 54%, 49%, 1) 0px, transparent 50%),
  radial-gradient(at 18% 22%, hsla(176, 61%, 36%, 1) 0px, transparent 50%),
  radial-gradient(at 39% 91%, hsla(174, 83%, 65%, 1) 0px, transparent 50%),
  radial-gradient(at 78% 80%, hsla(165, 89%, 71%, 1) 0px, transparent 50%);
}

.gradient-orange{
  background-color: hsla(30, 100%, 61%, 1);
  background-image: radial-gradient(at 68% 40%, hsla(35, 98%, 61%, 1) 0px, transparent 50%),
  radial-gradient(at 33% 61%, hsla(33, 76%, 75%, 1) 0px, transparent 50%),
  radial-gradient(at 44% 98%, hsla(351, 83%, 67%, 1) 0px, transparent 50%),
  radial-gradient(at 12% 74%, hsla(359, 98%, 72%, 1) 0px, transparent 50%),
  radial-gradient(at 52% 50%, hsla(105, 72%, 73%, 1) 0px, transparent 50%),
  radial-gradient(at 33% 62%, hsla(314, 69%, 65%, 1) 0px, transparent 50%);
}

.gradient-purple{
  background-color: hsla(272, 61%, 53%, 1);
  background-image: radial-gradient(at 87% 47%, hsla(266, 60%, 77%, 1) 0px, transparent 50%),
  radial-gradient(at 50% 16%, hsla(266, 58%, 65%, 1) 0px, transparent 50%),
  radial-gradient(at 42% 68%, hsla(266, 66%, 64%, 1) 0px, transparent 50%),
  radial-gradient(at 73% 6%, hsla(245, 64%, 72%, 1) 0px, transparent 50%),
  radial-gradient(at 98% 40%, hsla(257, 83%, 63%, 1) 0px, transparent 50%);
}

.gradient-violet{
  background-color: hsla(243, 48%, 48%, 1);
  background-image: radial-gradient(at 55% 90%, hsla(197, 77%, 62%, 1) 0px, transparent 50%),
  radial-gradient(at 67% 10%, hsla(239, 63%, 70%, 1) 0px, transparent 50%),
  radial-gradient(at 34% 67%, hsla(250, 67%, 67%, 1) 0px, transparent 50%),
  radial-gradient(at 73% 83%, hsla(249, 62%, 71%, 1) 0px, transparent 50%),
  radial-gradient(at 3% 90%, hsla(247, 87%, 60%, 1) 0px, transparent 50%);
}
